import React from 'react';
import { useTranslation } from 'next-i18next';
import { InView } from 'react-intersection-observer';

import { Typography } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';

import Avatar from 'src/components/ui/Avatar';
import type { SearchCommunityResult } from 'src/components/community/CommunitySearchForm';
import type { SearchCompanyToJoinResult } from 'src/components/ui/CompanySearchField';

import type { Company, Medium, PagesJaunesProSearchResult, User } from 'src/types/__generated__/graphql';
import useActivityToDisplay, { useCompanyActivityToDisplay } from 'src/hooks/useActivityToDisplay';
import { getPagesJaunesImageUrl } from 'src/utils/pagesJaunesUtil';

type MultiEntityAutocompleteOptionProps = {
    renderOptionProps?: React.HTMLAttributes<HTMLLIElement>;
    option: SearchCommunityResult | SearchCompanyToJoinResult;
    handleVisibilityChangedPJPro?: (isVisible: boolean) => void;
    shouldDisplayAddress?: boolean;
};

const MultiEntityAutocompleteOption: React.FC<MultiEntityAutocompleteOptionProps> = ({
    renderOptionProps,
    option,
    handleVisibilityChangedPJPro,
    shouldDisplayAddress = false,
}) => {
    const getActivityToDisplay = useActivityToDisplay();
    const getCompanyActivityToDisplay = useCompanyActivityToDisplay();
    const { t } = useTranslation(['community', 'common', 'company']);

    let name;
    let subtitle;
    let image: Pick<Medium, 'url'> | undefined;
    let fallbackText;
    let badgeIcon: 'PagesJaunes' | undefined;
    let address;
    if (option.__typename === 'User') {
        const user = option as unknown as User;
        const truncate =
            getActivityToDisplay(user).length > 45
                ? `${getActivityToDisplay(user).substring(0, 45)}...`
                : getActivityToDisplay(user);
        name = user.fullName;
        subtitle = `${truncate}${
            user.companyToDisplay?.name ? ` ${t('community:at')} ${user.companyToDisplay.name}` : ''
        }`;
        if (user.image) {
            image = user.image;
        }
        fallbackText = user.initials;
        if (shouldDisplayAddress && user.city) {
            address = `${user.city.zipCodes?.[0] ?? ''} ${user.city.name}`;
        }
    } else if (option.__typename === 'Company') {
        const company = option as unknown as Company;
        name = company.name;
        subtitle =
            getCompanyActivityToDisplay(company).length > 45
                ? `${getCompanyActivityToDisplay(company).substring(0, 45)}...`
                : getCompanyActivityToDisplay(company);
        if (company.image) {
            image = company.image;
        }
        fallbackText = company.initials;
        if (shouldDisplayAddress && company.address) {
            address = `${company.address.zipCode ?? ''} ${
                company.address.city?.name ?? company.address.cityName ?? ''
            }`;
        }
    } else if (option.__typename === 'PagesJaunesProSearchResult') {
        const pagesJaunesPro = option as unknown as PagesJaunesProSearchResult;
        name = pagesJaunesPro.merchantName;
        subtitle = pagesJaunesPro.activityLabel ?? '';
        if (pagesJaunesPro.thumbnailPath) {
            image = {
                url: getPagesJaunesImageUrl(pagesJaunesPro.thumbnailPath),
            };
        }
        fallbackText = pagesJaunesPro.merchantName[0];
        badgeIcon = 'PagesJaunes';
        if (shouldDisplayAddress && pagesJaunesPro.address) {
            address = `${pagesJaunesPro.address.zipCode ?? ''} ${pagesJaunesPro.address.cityName ?? ''}`;
        }
    }

    const isCompany = option.__typename === 'Company' || option.__typename === 'PagesJaunesProSearchResult';

    const content = (
        <Box
            key={option.id}
            {...(renderOptionProps as BoxProps)}
            display="flex"
            alignItems="center"
            ml="-0.25rem"
            data-target="autocomplete-option"
            data-target-id={`autocomplete-option-${option.id}`}
        >
            <Avatar
                image={image}
                alt={name}
                isCompany={isCompany}
                fallbackText={fallbackText}
                size={32}
                badgeIcon={badgeIcon}
                badgeColor="transparent"
            />
            <Box pl="0.625rem">
                <Typography variant="h6">{name}</Typography>
                <Box mt="-0.25rem">
                    <Typography variant="subtitle2">{subtitle}</Typography>
                </Box>
                {address && (
                    <Box mt="-0.25rem">
                        <Typography
                            data-target-id={`join-company-dropdown-address-${name?.replace(/\s/g, '-').toLowerCase()}`}
                            variant="caption"
                            alignSelf="start"
                        >
                            {address}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
    return handleVisibilityChangedPJPro ? <InView onChange={handleVisibilityChangedPJPro}>{content}</InView> : content;
};

export default MultiEntityAutocompleteOption;
