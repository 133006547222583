import { useContext } from 'react';

import { EventsContext } from 'src/contexts/EventsProvider';
import type { EventData } from 'src/types/__generated__/graphql';

export interface PageJaunesEventArgs {
    /**
     * The page jaunes merchant id (pro id)
     */
    pagesJaunesMerchantId: string;
    /**
     * The currently logged in user's id
     */
    userId: string | undefined | null;
}

const platformTypeWeb = 'web';

export enum EventType {
    searchResultImpression = 'search_result_impression',
    visitPage = 'visit_page',
    clickEmail = 'click_email',
    showPhoneNumber = 'show_phone_number',
    clickPhoneNumber = 'click_phone_number',
    clickWebsite = 'click_website',
    redirectToPagesJaunesPage = 'redirect_to_pages_jaunes_page',
    redirectToPagesJaunesPageRatingSection = 'redirect_to_pages_jaunes_page_rating_section',
}

const useEvents = () => {
    const { trackEvent } = useContext(EventsContext);

    const trackSearchResultImpressionPagesJaunesMerchant = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.searchResultImpression,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackVisitPagePagesJaunesMerchant = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.visitPage,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackClickEmailPagesJaunesMerchant = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.clickEmail,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackShowPhoneNumberPagesJaunesMerchant = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.showPhoneNumber,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackClickPhoneNumberPagesJaunesMerchant = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.clickPhoneNumber,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackClickWebsitePagesJaunesMerchant = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.clickWebsite,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackRedirectToPagesJaunesMerchantPage = ({ pagesJaunesMerchantId, userId }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.redirectToPagesJaunesPage,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    const trackRedirectToPagesJaunesMerchantPageRatingSection = ({
        pagesJaunesMerchantId,
        userId,
    }: PageJaunesEventArgs) => {
        const eventData: EventData = {
            pagesJaunesMerchantId,
            userId,
            eventType: EventType.redirectToPagesJaunesPageRatingSection,
            platform: platformTypeWeb,
        };
        trackEvent(eventData);
    };

    return {
        trackSearchResultImpressionPagesJaunesMerchant,
        trackVisitPagePagesJaunesMerchant,
        trackClickEmailPagesJaunesMerchant,
        trackShowPhoneNumberPagesJaunesMerchant,
        trackClickPhoneNumberPagesJaunesMerchant,
        trackClickWebsitePagesJaunesMerchant,
        trackRedirectToPagesJaunesMerchantPage,
        trackRedirectToPagesJaunesMerchantPageRatingSection,
    };
};

export default useEvents;
