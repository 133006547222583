import { css } from '@emotion/react';

import { colours } from 'src/constants/colours';

const autocompleteInputStyles = css`
    // Base style with no label
    .MuiAutocomplete-inputRoot {
        padding-top: 0;

        .MuiAutocomplete-input {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        &:hover {
            background-color: ${colours.black.O08};
        }

        &.Mui-focused {
            background-color: ${colours.primary.O08};
        }
    }

    .start-loading {
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

    // If there is a label
    .MuiInputLabel-root ~ .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        padding-top: 1.5rem;
        padding-bottom: 0.6rem;
    }

    &.Mui-focused {
        .MuiInputLabel-root {
            transform: translate(0.75rem, 0.5rem) scale(0.75);
        }
    }
    .MuiAutocomplete-endAdornment {
        top: auto;
    }
`;

export default autocompleteInputStyles;
