import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'next-i18next';
import { v4 as uuid } from 'uuid';

import { ListSubheader } from '@mui/material';
import type {
    AutocompleteInputChangeReason,
    AutocompleteProps,
    AutocompleteRenderGroupParams,
    AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import MuiAutocomplete from '@mui/material/Autocomplete';
import type { FilterOptionsState } from '@mui/material/useAutocomplete';

import type { Props as TextFieldProps } from 'src/components/ui/TextField';
import { StyledTextField } from 'src/components/ui/TextField';
import Loader from 'src/components/ui/Loader';

import theme from 'src/utils/theme';
import autocompleteInputStyles from 'src/constants/autocompleteInputStyles';
import { styledComponent, styledTag } from 'src/utils/styled';
import { colours } from 'src/constants/colours';

export const StyledAutocomplete = styledComponent(MuiAutocomplete)`
    ${autocompleteInputStyles}
`;

const StyledGroupName = styledComponent(ListSubheader)`
    background-color: ${theme.palette.background.paper};
    top: -0.75rem;
    font-family: Lato;
    font-weight: 700;
    font-size: 0.75rem;
    color: ${colours.blackOpacity.O60};
`;

const StyledGroupValue = styledTag('ul')`
    padding: 0 1rem;
    color: ${colours.primary.main};
`;

export interface Item<T = string> {
    value: string | number;
    label: T;
    category?: string;
}

interface ExtendedItem<T = string> extends Item<T> {
    valueToFake?: Item['value'];
}

interface Props<T = string> {
    isLabelTranlated?: boolean;
    label?: string;
    name: string;
    items: Array<Item<T>>;
    onChange?: (value?: string | null | number, selectedLabel?: string | null) => void;
    onSearchInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
    groupBy?: (item: unknown) => string;
    placeholder?: string;
    startIcon?: ReactNode;
    disabled?: boolean;
    hideErrors?: boolean;
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: unknown,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
    getOptionLabel?: (option: unknown) => string;
    renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
    filterOptions?: (options: unknown[], state: FilterOptionsState<unknown>) => unknown[];
    onBlur?: (value: string) => void;
    blurOnSelect?: boolean | 'mouse' | 'touch';
    popupIcon?: React.ReactNode;
    loading?: boolean;
    loadingPosition?: 'start' | 'end';
    InputLabelProps?: TextFieldProps['InputLabelProps'];
    InputProps?: TextFieldProps['InputProps'];
    noOptionsItems?: Array<Item<T>>; // Items displayed when no options
    inputName?: string;
    allowFreeInput?: boolean;
    CustomPaper?: AutocompleteProps<
        unknown,
        boolean | undefined,
        boolean | undefined,
        boolean | undefined,
        'div'
    >['PaperComponent'];
    CustomPopper?: AutocompleteProps<
        unknown,
        boolean | undefined,
        boolean | undefined,
        boolean | undefined,
        'div'
    >['PopperComponent'];
}

const CustomAutocomplete: <T>(props: Props<T>) => React.ReactElement = ({
    name,
    label,
    onChange,
    onSearchInputChange,
    items,
    isLabelTranlated = true,
    placeholder,
    startIcon,
    hideErrors,
    renderOption,
    getOptionLabel,
    renderGroup,
    loading,
    loadingPosition = 'end',
    noOptionsItems,
    onBlur,
    inputName,
    allowFreeInput,
    CustomPaper,
    CustomPopper,
    ...rest
}) => {
    const { t } = useTranslation(['common']);
    const [inputField, _metaInputField, { setValue: setInputFieldValue }] = useField(inputName ?? `${name}Search`);
    const [field, meta, { setValue }] = useField(name);
    const [displayNoOptionsItems, setDisplayNoOptionsItems] = useState(false);

    const handleChange = (item: ExtendedItem | null, reason: string) => {
        if (onChange) {
            onChange(item?.value, inputField.value as string);
        }
        if (displayNoOptionsItems) {
            setDisplayNoOptionsItems(false);
        }
        setValue(item?.valueToFake ?? item?.value);
        // if we click on enter with no option, the item value is the string of the input field
        const inputValue = reason === 'createOption' ? (item as unknown as string) : item?.label;
        setInputFieldValue(inputValue ?? '');
    };

    const handleSearchInputChange = (
        _ev: React.SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason,
    ) => {
        // displayNoOptionsItems = true if we don't find an option matching the input value
        // in this case we want the value to be undefined (because reason = input) and we use onChange function to put the good value for field and inputField
        setDisplayNoOptionsItems(
            Boolean(
                noOptionsItems?.length &&
                    !items.some((i) => String(i.label).toLowerCase().includes(value.toLowerCase())),
            ),
        );

        // reason = clear => when clear the input
        // reason = reset => when select dropdown value
        // reason = input => when writing in the input
        if (reason !== 'reset') {
            setValue(undefined);
            setInputFieldValue(value);
        }

        onSearchInputChange?.(value, reason);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
        if (displayNoOptionsItems) {
            setDisplayNoOptionsItems(false);
        }
        onBlur?.(ev.target.value);
    };

    const defaultRenderGroup = (params: AutocompleteRenderGroupParams) => (
        <div key={params.key}>
            <StyledGroupName>
                {isLabelTranlated
                    ? params.group
                    : t(params.group.includes(':') ? params.group : `common:${params.group}`)}
            </StyledGroupName>
            <StyledGroupValue>{params.children}</StyledGroupValue>
        </div>
    );

    const defaultGetOptionLabel = (option: unknown) => {
        const item = option as Item;
        if (displayNoOptionsItems) {
            return inputField.value as string;
        }
        return isLabelTranlated
            ? String(item.label)
            : String(t(item.label.includes(':') ? item.label : `common:${item.label}`));
    };

    const defaultRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: unknown): React.ReactNode => {
        const item = option as Item;
        return (
            <span {...props} data-target="autocomplete-option" data-target-id={`autocomplete-option-${item.value}`}>
                {defaultGetOptionLabel(item)}
            </span>
        );
    };

    const formatedNoOptionsItems: ExtendedItem[] | undefined = useMemo(
        () =>
            noOptionsItems?.map((i) => {
                const value = uuid(); // The value must be unique for no options items
                const valueToFake = i.value; // The valueToFake is used to fill the field instead of value
                return {
                    ...i,
                    value,
                    valueToFake,
                    label: String(i.label),
                };
            }),
        [noOptionsItems],
    );

    const fieldValue = items.find((item) => item.value === field.value) ?? null;
    // for the allowFreeInput use case we need to set the inputValue (the value we see in the input)
    // basically it should be the inputField.value but in some cases it's undefined but we have a fieldValue
    // so in this case we take the fieldValue?.label
    const inputValue = (inputField.value as string | undefined) ?? (fieldValue?.label as string | undefined) ?? '';
    return (
        <StyledAutocomplete
            {...rest}
            PaperComponent={CustomPaper}
            PopperComponent={CustomPopper}
            loading={loading}
            value={fieldValue}
            freeSolo={allowFreeInput}
            forcePopupIcon={allowFreeInput}
            inputValue={allowFreeInput ? inputValue : undefined}
            onChange={(event: React.SyntheticEvent, item: unknown, reason: string) =>
                handleChange(item as Item | null, reason)
            }
            onBlur={handleBlur}
            getOptionLabel={getOptionLabel ?? defaultGetOptionLabel}
            renderGroup={rest.groupBy && (renderGroup ?? defaultRenderGroup)}
            renderOption={renderOption ?? defaultRenderOption}
            options={
                displayNoOptionsItems && formatedNoOptionsItems?.length ? (formatedNoOptionsItems as Item[]) : items
            }
            loadingText={t('common:loading')}
            noOptionsText={t('common:general.noResult')}
            onInputChange={handleSearchInputChange}
            renderInput={(params) => (
                <StyledTextField
                    {...inputField}
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    helperText={!hideErrors && meta.touched ? meta.error : undefined}
                    error={Boolean(!hideErrors && meta.touched && meta.error)}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        startAdornment:
                            loading && loadingPosition === 'start' ? (
                                <Loader className="start-loading" color="inherit" size={20} padding={0} />
                            ) : (
                                startIcon
                            ),
                        endAdornment: (
                            <>
                                {loading && loadingPosition === 'end' ? (
                                    <Loader color="inherit" size={20} padding={0} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ ...params.inputProps, ...rest.InputProps?.inputProps }}
                    InputLabelProps={{
                        shrink: Boolean((params.inputProps as { value?: boolean } | undefined)?.value),
                        ...params.InputLabelProps,
                        ...rest.InputLabelProps,
                    }}
                />
            )}
        />
    );
};

export default CustomAutocomplete;
